import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const title = 'Trenchmore';

const article = `
# Trenchmore -An Irish Dance in Tudor and Stuart England?
### _Seán Donnelly_

## I

The Christmas season of 1551-2 at the court of Edward VI of England saw the earliest recorded reference to what is probably the best-attested English dance of the Tudor and Stuart period, the wild and boisterous 'Trenchmore'.1 The expenses that year of the king's Lord of Misrule - whose function was to oversee the annual Christmas celebrations 2 - included a unique entry for 'his dauncers' recording the cost of 'thre garments of sarsenett with iij payre of sloppes of owde store, for them that daunsed trenchemore ...'.3 While the context suggests that an exhibition-dance was in question here, by 1598 'Trenchmore', was being called a country-dance: 'Paris led the Shaking of sheets with Domitia, and Mnester the Treuchmouth with Messalina'.4

The style of performing 'Trenchmore' appears to have been sufficiently unusual to attract comment. In The Gentle Craft Part II (London, c. 1598), Thomas Deloney wrote of a character that 'like one dauncing the trench more he stampt up and downe the yard, holding his hips in his hands.'5 The dance was also distinguished by the number of its 'tricks' or 'capers', and 'capering' is the verb most often used to describe its performance.6 Nicholas Breton, in Wits Trenchmour, ... (London, 1597), wrote of having discovered 'such a Galiard as had a trick above Trenchmour, ...'.7

A rather sickening reference to the dance's 'tricks' is to be found in a letter of 26 June 1592 to Elizabeth I from Richard Topcliffe, her pursuivant and rackmaster. Regarding the recent seizure of Father Robert Southwell SJ (1561-95), Topcliffe wrote that 'if your Highness' pleasure be to know anything in his heart,' simply hanging the priest in fetters against a wall, his feet just off or barely touching the ground, 'his hands stuck as high as he can reach ..., like a trick at Trenshmeare, will enforce him to tell all, ...'.8

As 'capering' so often described the dance, it is no surprise that it was occasionally associated with apes. In John Marston's work, The Metamorphisis of Pigmalions Image, ... (London, 1598), a character 'doth curtsie, and ... Trenchmore with Apes, ...'.9 When Richard Stanihurst (1574-1618) charged an opponent in 1577 with expressing opinions unbecoming in a clergyman, he wrote that '... trulie they beeset a diuine as well, as for an asse to twang quipassa on a harpe or gitterne, or for an ape to strike trenchmore in a pair of buskins and a doublet.'10

The boisterous and lively nature of 'Trenchmore' is also apparent from the literary references where the term was used to mean jollification in general. 'We had a Wedding to day and the young fry tickle Trench-more' occurs in William Sampson's play, The Vow Breaker (1636).11 The term was apparently used in the same sense in the 1672 song, 'The West Country Jigg: or, A Trenchmore Galliard', which celebrated the 'Trenchmore Lasses' from 'Trenchmore Lea'.12 More intriguing was the use of the term to describe rapid, sometimes agitated movement, an extension from the dance's liveliness. 'Thy tongue trips trench-more' is said to a garrulous character in The London Prodigal (1605),13 while 'my braines daunce trenchmore' in a song of 1608 seems to be an apt description of a throbbing hangover.14

Further examples of this use imply that 'Trenchmore' had also become a type of dance.15 About 1600 the famous Elizabethan dancer and clown, Will Kemp, wrote of his successful dancing career: 'Some sweare, in a Trenchmore, I have trode a good way to win the world ...', and also described a public flogging as 'a dance of Trenchmore at the whipping crosse'. 16 A character in Lording Barry's play, Ram Alley, or Merrie Trickes (London, 1611), threatens to make an opponent 'dance a trenchmoore to my sword'.17 The term is also used in this sense in two plays by Beaumont and Fletcher. An artillery bombardment in The Island Princess (1621) makes '... all the windows ith' town dance a new trench-more, ...'; while a visitor to a lunatic asylum in The Pilgrim (1622) is warned not to disturb certain inmates, who 'will make you start if they but dance their trenchmores...'.18

Occasionally, whether a dance or dance-type was intended is not clear. To the jurist John Selden (1584-1654), 'Trenchmore' was a wild free-for-all that had become too popular for his taste:

> The Court of England has much altered. Att a Solemne dancing, first you have the grave measures, then the Corantoes and the Galliards, & all this was kept up wth ceremony, att length they fall to Trenchmore, & so to the Cushion Dance, Lord & Groome, Lady and Kitchen Maid, no distinction: So, in our Court. In Queen Eliz: time, Gravitie and state was Kept upp. In King James Time things were pretty well [.] But in K Charles time there has binn nothing but Trenchmore and the Cushion Dance, totty polly, hoyte come Toyte.19

Robert Burton, in The anatomy of melancholy (London, 1624), also pictured 'Trenchmore' as a wild unrestrained gallop:

> If once we be in love, young or old, though our teeth shake in our heads, like virginall Jacks, or stand paralel asunder like the arches of a bridge, there is no remedy, we must dance Trenchmore for a need, over tables, chaires and stooles, &c.20

John Taylor (1580-1653), 'The Water Poet' - he spent most of his life as a waterman on the Thames - also implies that 'Trenchmore' was a type of dance in A navy of landships (1627):

> "Nimble-heel'd mariners, like so many dancers, capering a morisco [morris dance], or Trenchmore of forty miles long, to the tune of 'Dusty, my dear,' 'Dirty, come thou to me,' 'Dun out of the mire,' or 'O wail in woe or plunge in pain:' all these dances have no other music."21

There are also some references to the tune of 'Trenchmore' as distinct from the dance. In a morality text of 1564 the minstrel Mendax 'plaieth tricke vpon the Gitterne, and daunce Trenchemore and hey de Gie, ...'.22 William Webb hints that 'Trenchmore' was a popular air for songs in his Discourse of English Poetrie (London, 1580), p. 61: '...neither is there any tune or stroke which may be sung or plaide on the instruments, which hath not some poetical ditties framed according the numbers thereof ... some to Trenchmore ...'. And Taylor the Water Poet wrote in A Merry Wherry-ferry voyage (1642): 'Heigh, to the tune of Trenchmore I could write / Of the valiant men of Cromer's sad affright; ...'.23 In The Rehearsal, a comedy by George Villiers, 2nd duke of Buckingham, first performed in 1672, the process of an eclipse is demonstrated by characters representing the Sun, Moon and Earth dancing a hey. As each of the three makes an entrance, they sing a verse to a named tune, the Sun to 'Trenchmore.24 'A New Ballad to the tune of Trenchmore' was published in 1704 but the words do not suit the usual tune. 25

The music of 'Trenchmore' occurs as the basis for lute-fantasias in various manuscripts dating from c. 1560 to the late seventeenth century - though editors have found great difficulty in extracting the basic melody from these works, particularly the earlier ones. 26 Presentable versions appear in Thomas Ravenscroft's Deuteromelia, ... (London, 1609), as the air of two songs, 'Tomorrow the Fox will come to Town' and 'Willy prithee go to bed' (nos. 20 and 21). 27 However, the best instrumental version of 'Trenchmore' is considered to be that which John Playford published as a country dance - 'longways for as many as will' - in the 2nd edition of The Dancing Master (London, 1652) - and which appeared in all later editions. 28 This version was one of the two 'very ancient country dances' - the other being 'The Shaking of the Sheets' - which Sir John Hawkins republished in his History of music (1776). 29 Outside the Dancing master, 'Trenchmore' is rarely found, at least under its own title. Apparently the tune did survive in England as the music to which was performed the traditional cushion-dance 'Bab-at-the-Bowster'. 30 Early this century, 'Trenchmore' was one of the country dances Cecil Sharp and his colleagues in the English Folk Dance Society republished - occasionally in a rearranged form - from Playford's Dancing Master. 31

## II

Despite the popularity of 'Trenchmore', its title has never been properly explained. 32 Yet if it is a non-English word, the fairly consistent spelling (allowing for the vagaries of sixteenth- and seventeenth-century English) suggests that the title did not sound too foreign to English ears. A suggestion that the title was an Irish word, current since early this century, has been accepted by writers on the history of dancing in Ireland. 33

This was originally put forward by J.G. O'Keefe and Art O'Brien in the historical introduction to Rince Gaolach: a handbook of Irish dances (London, 1912). Having noticed dances alluded to in seventeenth-century English sources, such as the 'Irish Hay' and the 'Irish Trot', the authors continued (p. xi):

> Allusions to a dance called Trenchmore are quite as numerous as they are to the Hey in sixteenth century literature. ... Having regard to the fact that we find the word Rinnce in such forms as Ring, Trink, Trenk, etc., it is nor unreasonable to assume that the word Trenchmore is simply Rinnce Mór, a term which, even to the present time, is applied to certain Irish country dances.

Dance with titles similar to Rince Mór were also to be found in Gaelic Scotland,34 but despite being accepted by later writers, the proposed explanation is not very likely. It would be very difficult to explain how Irish rince 'dance' became 'trench-' on being borrowed into English. Where rince (plural rincí) does appear in an anglicised form, it is invariably 'rinka', 'rinky'; and while 't' can develop before 'r' in an Irish word - usually when the preceding consonant was 'n' or 's' - this only happens internally, not initially. 35

The most authoritative statement on what the title Trenchmore was likely to have been was that of Sir James A.H. Murray, first chief editor of the Oxford English Dictionary, in Notes and Queries cxxix (Jan.-June 1914), 107. Murray was then working on the fasciculus of the dictionary containing Trenchmore - it was to be his last contribution to the enterprise he had nurtured for more than thirty years - and his request for information on the title, which was to prove fruitless, was clearly an effort to resolve its meaning prior to publication. 36 The title was, Murray thought, a proper noun, and likely to be either the name of a family or a place, suggestions he incorporated in the entry on Trenchmore in the 1st edition of the OED. 37

That the title could be a surname is the less likely suggestion. Neither Trenchmore nor a variant is to be found in the standard dictionaries of British surnames 38 - though a 'Trankmore' (apparently a very rare surname) is found in the late sixteenth century. 39 Besides, dances and tunes with unqualified surnames as titles appear to be unknown, and even titles consisting of forename and surname are rare in early sources. 40 If Trenchmore was a placename, the first element is obviously the English word 'trench', a French loanword in Middle English (ultimately deriving from the Latin truncare), which in a placename can signify 'a cutting through a wood' in addition to its usual range of meanings. The word does occur in some minor English placenames - The Trench, for instance, or Trench Lane - but it is extremely rare, with older synonyms such as 'ditch' and 'fosse' being more common. 41 However, no placename Trenchmore (or a variant) is recorded in the published gazeteers of English counties. 42 The dance-title once appears as 'French-more', and there is a place called Frenchmoor (of uncertain origin) in Hampshire; but this version of the title is a scribal error. 43

While there is no English place that would suit Murray's suggestion, there is an Irish one that would fit perfectly: Trenchmore, a townland of 424 acres (approx.) in the parish of Coolaghmore, barony of Kells, co. Kilkenny. 44 The Irish for it is An Trinse Mór, 45 which has been wrongly translated as 'A Great Hollow', instead of 'The Great Trench'. 46 Practically all that is known concerning Trenchmore appears in William Carrigan's History of the diocese of Ossory (3 vols., Dublin, 1902-5), II, pp 336-7:

> Trenchmore: - West of Cooliaghmore, within half a mile of the Co. Tipperary, and in the midst of a wide tract of moor, stood the Shanna-chushlawn-Threenshamoore, or old castle of Trenchmore. They say it was built "after a French model with bow windows," by Colonel John Butler. The Colonel occupies a large place, in the traditions of locality, as an unflinching champion of Catholic rights. He is said to have fought on the losing side, at the Boyne, in 1690. He was known as Shawn a-chushlawn (Seaghan a' chaisleainn), or "Jack of the Castle," from his having built, and resided in, Trenchmore castle; and the tradition is that his sobriquet, of which he himself was the original holder, descended from him to his grandson (great-grand-nephew?), Mr. John Butler, afterwards 17th Earl of Ormond, the great-grandfather of the present Marquis of Ormond.

> The castle of Trenchmore (the Great Trench) is now a thing of the past, having been all destroyed except a ruin called the "kitchen," and a small part of the foundations of the main building. A road or avenue led hence, to the north, through a field called "the Avenue," meeting the Cooliagh road at Bosheen-an-Affering. Another avenue, with a paved road, lined with two ranks of fine old trees yet remaining, also led hence, partly in the direction of Cooliaghmore, breaking out on the "new road," a little to the south of the old chapel of Cooliagh. Along this latter avenue there are two wells, one of which is called Thubber-Kinn-aedha, or Kennedy's Well; the other is called Thubbereemoora, or O'Moore's Well.

The oral traditions Carrigan noted down from Irish speakers in the area are important in that they confirm the meaning of the placename, and that it already existed in the seventeenth century. This latter point is especially important because Trenchmore is not attested in a documentary source before 1777. 47 Normally this would not matter: many Irish placenames, though obviously very old, were not collected before the systematic surveys of the early nineteenth century. These, however, were usually in remote areas, whereas Trenchmore is in co. Kilkenny, which, along with most of co. Tipperary, formed the vast lordship of the Butlers, earls (later dukes and ultimately marquises) of Ormond. The vast surviving collection of Ormond deeds means that the placenames in the area are better-documented than most others. But it appears that Trenchmore was not erected into a separate townland until comparatively late. The parish in which it is, Coolaghmore, is recorded in Ormond deeds from the late fourteenth century down to the seventeenth, but as both a parish and a townland. This also holds true of the mid-seventeenth century Book of Survey and Distribution for co. Kilkenny. 48 And while no Down Survey parish-maps for the barony of Kells have survived, Coolaghmore is marked, again as both a townland and parish, in relation to the town and liberties of nearby Callan, co. Kilkenny. 49

There are no archaeological remains whatsoever in Trenchmore today, and whatever traces of the castle were there in Carrigan's day have disappeared. Neither does any trace remain of the earthwork from which the area was named. 'Trench' was borrowed into Irish in the form tr(e)inse, and in the sixteenth and seventeenth centuries it was used almost invariably in the military sense of a defensive earthwork, either temporary or permanent. 50 The term is found in two English placenames in the Pale: 'le Trench lande' in a 1518 rental of the earl of Kildare's lands may be the modern townland of Blacktrench, near Naas, 51 while the earthwork that gave its name to Blackditch in Ballyfermot, Dublin, is called 'The Trench' in 1435. 52

The 'Great Trench' of the placename may possibly have marked the border of co. Kilkenny and co. Tipperary - just as 'the Raduff trench' [Rathduff Trench] is thought to have done between co. Kilkenny and co. Carlow. 53 The barony of Kells came into the possession of the earls of Ormond in the fifteenth century; but because the earls held their Kilkenny and Tipperary lands under different conditions, the county boundary - which is also the provincial one between Leinster and Munster - would have retained its importance. But whatever the origin and function of 'The Great Trench' was, there appears to have been nothing left of the earthwork itself by the seventeenth century. In the mid-1650s, The Civil Survey recorded that 'the brooke of Moynaghe' (the Munster River) delineated part of the border between the baronies of Slieveardagh, co. Tipperary, and Kells, co. Kilkenny; elsewhere the boundary followed various 'gutters' (brooks or small rivers) and ditches. 54

Almost certainly the principal reason why Trenchmore is so poorly attested in early records was that, notwithstanding the later (?) presence of the castle, it was remote and of little importance because of the poor quality of the land. In 1828 the Irish diarist Amhlaobh Ó Súilleabháin, a schoolmaster in Callan, remarked on the wildness and remoteness of Colaghmore, the parish in which Trenchmore is, when he visited it to collect the Catholic Rent. He also noted that several local placenames contained the Irish word aifreann 'mass' - including coincidentally Clais an Aifrinn, 'The Mass Ditch/Trench' - evidence that mass had been celebrated in secret there during the Penal times.55

## III

While direct evidence is lacking, a reasonable circumstantial case can be made for the proposal that the dance 'Trenchmore' was named after Trenchmore, co. Kilkenny, and its early transmission to London can be accounted for. Dances and tunes with titles consisting of unqualified placenames - that is just the name of the place and nothing else - are extremely rare. Nowadays the only musical genre in which they occur to any great degree is that of the metrical psalms. These evolved in England and Scotland the aftermath of the Reformation, when the emphasis was on plain simple tunes that an ordinary congregation could sing. Most of the psalm-tunes bear the names of well-known places in England and Scotland (and occasionally on the Continent). Tunes with titles such as 'York', 'Windsor', 'Hackney', are found in the English tradition, and 'Dundee', 'Elgin', 'Kilmarnock', in the Scottish. Alongside these, however, are tunes that carry in the names of less easily-recognised places: 'Bangor', 'Crimond', 'Stroudwater', 'Newtown', 'Coleshill'. Not all the tunes date from the sixteenth century; but ones added in later centuries, even by known composers, were given the same kind of title. 56 (Incidentally, in the early eighteenth century, Ulster Presbyterians knew 'Coleshill' as 'Dublin', 57 and the Church of Ireland later used a psalm-tune, 'Athlone', attributed to Turlough O'Carolan.) 58

This kind of title may once have been common in secular dance-music as well: an English dance-manuscript of c. 1500, for example, contains instructions for dances named 'Bolingbroke', 'Eglyntyn', and 'Northumberland', as well as for 'Newcastle dance'. 59 Later in the sixteenth century, though, and into the seventeenth, such titles become far less common. 60 Of the 535 country dances published in Playford's Dancing Master between 1651 and c. 1728, just ten per cent had unqualified placenames as titles. Again these titles range from the well-known to the fairly obscure. Some Continental names probably refer to contemporary military events; yet other English places named could scarcely have been heard of outside their own locality. Possibly these last were old dances, or at least dance-tunes, which the Playfords collected and published, perhaps in updated arrangements. 61

Indications from Ireland also suggest that dances with placename-titles were old. The bulk of the evidence for dancing in Ireland during the sixteenth and seventeenth centuries originates in the Pale around Dublin. The 'Irish' dances that struck English observers as strange did not do so because of anything intrinsically Irish about them, but because they belonged to types that had long disappeared from fashionable society in England. 62 The comments of Fynes Moryson, who served in Ireland from 1600 to 1603 as secretary to Lord Deputy Mountjoy, appear to confirm this. The Irish, he wrote, 'delight much in dansing, vsing no Arte of slow meaures or lofty galliards, but only Country dances, whereof they have some pleasant to beholde, ...'. 63

Dances current during the sixteenth and seventeenth centuries in Fingal, the northern half of co. Dublin, included the likes of 'Balrothery' and 'Baldoyle', both named after villages in the area and attested more than once. Other dances named for places in Fingal are not so certain in that they are only named in one source. It is also interesting that dances named for places elsewhere in Ireland were known in Fingal, as were dances and songs with titles in Irish. The early eighteenth century saw an occasional dance named after a place in Ireland surfacing in London. A country-dance called 'Cork' was published in 1733,64 while later in the decade an exhibition dance called 'Kilkenny' - occasionally 'The Kilkenny' - was to be seen in London theatres. 65 Back in Ireland 'Drogheda' or 'Droghedy', also 'Drocketty's March' or 'The Drocketty March' was the name of a dance and at least two tunes collected in the nineteenth and twentieth centuries. The dance was performed at wakes down to the nineteenth century, and was also the title of the stick-dance Wexford mummers performed. 66

In the light of the foregoing examples, a dance named after Trenchmore, co. Kilkenny would not be exceptional. However, there is no evidence from England that 'Trenchmore' was ever thought to be Irish, and it is never mentioned in an Irish source. 67 But this lack of a national label is relatively unimportant: one very well-attested country dance of the period 1530-1630, the previously mentioned 'Hey de Gie' (also spelt 'Hay the Gy' and 'Haydeguies'), was obviously of French origin, but it is never referred to as such. 68 This is equally true of the first Irish song of which there is a record, Cailín ó chois tSiúire mé, 'I am a girl from beside [the river] Suir' - the 'Calen o custure me'of Shakespeare and his contemporaries (usually shortened to 'Callino'). First heard of as the air of an English song in 1582 - which implies that it was already well-known in England - the tune, though recognised as foreign, is rarely identified as Irish. 69

As to how a dance such as 'Trenchmore' could have reached the court of Edward VI in 1551-2, it would be difficult not to associate its appearance with the presence at court of the lord in whose territory Trenchmore actually lay: Tomás Dubh Butler (1532-1614), 10th earl of Ormond - whom even Tudor courtiers called 'Tom Duff'. 70 In 1543, James Butler, 9th earl of Ormond, had requested Henry VIII to grant a place at court to his heir Thomas, whom he despatched to court the next year. Already at court were two of Thomas' first cousins, Lady Margaret Fitzgerald, daughter of the 9th earl of Kildare - immortalised by the poet Thomas Surrey as 'The Fair Geraldine' - and Barnaby Fitzpatrick (c. 1535-81), son of the baron of Upper Ossory. Thomas and Barnaby joined group of ten or twelve noble youths whom Henry VIII had selected to be educated with his son Edward. Barnaby was appointed Edward's 'proxy for correction', or whipping boy, and it says much for Edward's good behaviour that Barnaby became his closest friend. While most of the other pupils changed in the course of the years, the two cousins remained until the school group was formally disbanded in autumn 1552, five years after Edward had come to the throne. Ormond actually participated in the Christmas festivities that year - the occasion of the first appearance of 'Trenchmore' - disguised as an 'Almain', or German. 71

By 1552 Thomas had reached his majority and succeeded to earldom of Ormond, his father having died of food poisoning in London in 1546. When Edward VI died in July 1553, his catholic sister Mary came to the throne, and Ormond appears to have had no difficulty in accommodating himself to the changed regime. Mary retained the earl at court for a further year, allowing him to return to Ireland in October 1554, along with Barnaby Fitzpatrick and Gerald Fitzgerald, 11th earl of Kildare. 72 Throughout the rest of his long life, Ormond maintained his family's tradition of unswerving loyalty to the crown, spending long periods at court, where he exploited the powerful connections his early education had given him. He also enjoyed the favour of Elizabeth I - they were related through her mother Anne Boleyn - who called him her 'black husband'. 73 In 1588 the queen bestowed on Ormond what an Irish poet described as áirdchéim Ridireacht Gáirtéir, / ainm nár ghnáth é ar Éirionnach ..., 'the high honour of Knighthood of the Garter, a title rare on an Irishman.' 74

While the Butlers of Ormond never became as gaelicised as some other Anglo-Norman families, 10th earl's household would have provided a milieu through which the occasional Irish song or dance could have passed into circulation in England. Given that the River Suir rises and flows through co. Tipperary - in its lower reaches it is the southern border of cos. Tipperary and Kilkenny with co. Waterford - Cailín ó chois tSiúire mé is likely to have been such a song. 75 Ormond built a beautiful Tudor manor house at Carrick-on-Suir and was actually praised in an Irish poem as 'earl of the Suir'. 76 Ormond would have maintained the usual complement of professional musicians expected of an Elizabethan aristocrat, and also enjoyed the services of one of the most celebrated Irish harpers of the late sixteenth century, 'Blind Cruise'. 77 One of his musicians may have composed 'The Countess of Ormond's Galliard' (1588), named for his second wife, Elizabeth Sheffield. 78 No court dance named for the earl himself seems to have survived, though he could possibly have been the subject of 'tom duff', a tune-title found on a list of English tunes and dances written, mostly phonetically, in a Welsh manuscript of 1595. 79 Ormond would have had no particular connection with Trenchmore, or with the barony of Kells. But the neighbouring barony of Callan was 'one of the five ancient flowers' of his patrimony 80 - the manors in cos. Kilkenny and Tipperary granted to the first Butler that formed the core of the lordship - and one of his epithets in Irish was 'lord of fair Callan'. 81

Ormond's detention at court until 1554 would have necessitated much coming and going of his servants and followers between London and Kilkenny, especially in the years after his father's death in 1546. There was, therefore, a direct channel of communication through which 'Trenchmore' could have been introduced to the royal court in London. The circumstances of its first appearance in 1551-2 do suggest that the dance was then a novelty, and while it was not found in a specifically Irish context, dramatic depictions of Irish life and character were in vogue at court during the early 1550s. While previous English monarchs had been lords of Ireland, Henry VIII had become king in 1540, initiating the policy of surrender and regrant that brought various Irish and Anglo-Irish lords to London. In autumn 1542 Conn Bacach O'Neill appeared at Greenwich to surrender his lands and accept the title of earl of Tyrone. 82 Apparently he did not create as great a sensation as his youngest son, the ruthless Shane, did twenty years later, when he appeared before Elizabeth with a bodyguard of axe-bearing galloglasses - and, more importantly, a team of very astute Pale lawyers. 83 While these lords donned English dress for their London-visits, their followers would have kept their Irish dress, and would have appeared as strange and exotic to English courtiers as the natives of Greece, Persia or Turkey. Certainly, a stir had been created in May 1544 by the wild and outlandish appearance of the force of Irish kerne (lightly-armed foot-soldiers) who passed through London - where Henry VIII reviewed them in St James' Park - 'with bagpipes before them' enroute to the Siege of Boulogne (May-September 1544). 84 And it may be no coincidence that the male 'Irish' figures in the court masques were usually dressed as kerne, 85 and this was true of the occasional masque in the 1620s and 30s, by which time kerne had disappeared as a military class and Irish costume was falling into disuse. 86

Whatever the ultimate cause of this interest in things Irish, the royal court saw four performances on Irish themes between late 1551 and early 1557: The masque of Irishmen on Twelfth Night 1551-2; The masque of Irishwomen at Shrovetide 1552; William Baldwin's Irish play of the state of Ireland at Easter 1553; and The masque of Almains, pilgrims and Irishmen on 25 April 1557. 87 The cost of mounting these masques and plays included money paid for Irish costumes, weapons and women's headresses. 88 Dramatic performances on Irish subjects were far less popular during the reign of Elizabeth I, 1558-1603, and of her successor, James I. In sixty years only two performances on Irish themes are known to have been staged: The Irish Knight at Whitehall on Shrove Monday 1577, and The Irish masque at court, by Ben Jonson, at the same venue during Christmas 1612. 89

By the late sixteenth century, Elizabethan courtiers would have found little novelty in dramatic representations of Irishness: the country and its apparently never-ending wars would have been all too real to them. Irish people would also have been more familiar in London. A strong Irish colony already existed by the late sixteenth century, and many veterans of the Irish wars brought Irish servants back to London with them and, as was to be case with Negroes in the eighteenth century and Indians in the nineteenth, such servants became fashionable. Mainly horse-boys and footmen, they were much valued for their fleetness of foot and stamina, and went about in their native costume, carrying a kerne's dart as a badge of office. 90

Music and dancing were major elements in court-masques, and the 'Irish' ones were no exception. Though not an exclusively Irish musical instrument, the bagpipe, in the form of the mouth-blown píb mhór was strongly associated in Tudor eyes with Ireland, where it functioned as the military musical instrument of the kerne. Three shillings were paid 'for the hier of an yrishe bagpipe plaier' to play in The masque of Irishmen on Twelfth Night 1551-2; the 'bagge piper' (not said to have been Irish) who played in The masque of Irishwomen the following Shrovetide received 3s. 4d.; and the musicians in The masque of Almains, pilgrims and Irishmen on 25 April 1557 comprised 'iiij drommes & fifes & two bagge pipes ...'. 91

Were the dancing of 'Trenchmore' to be associated with one of these Irish performances, this would strengthen the suggestion that the dance was of Irish origin. But even in the Lord of Misrule's entourage dramatic representations of certain aspects of Irishness were to be found; for instance, the costumes supplied for the lord's followers during the 1552-3 Christmas season included 'Irish apparel' for a man and woman. 92 Descriptions of the lord's formal visits to the city of London on 3 January 1551-2, and on the same date in 1552-3, mention bagpipes among the musical instruments of his entourage. 93 Though no piper is specifically referred to on the later occasion, the accounts for 1551-2 recorded the cost of 'A garmente of Russet damaske' for the Lord of Misrule's 'Mynstrell' - 'the Iryishe bagpyper' - probably the same man as played in The masque of Irishmen. 94

The above entry immediately precedes that for the dancers who performed 'Trenchmore', and though such a collocation does not prove a connection, the bagpipe often accompanied the dancing of Irish characters on the English stage during the sixteenth and seventeenth centuries. Twelve Irish footmen, six men and six boys, dance to the 'bagpipe and other rude music' in Ben Jonson's Irish masque at court (1612). 95 In The Welsh Embassador (London, 1624), an Irish footman, on hearing that a musician lives next door, says 'Crees sa me if I heare de pipes goe I cannot forbeare to daunce an Irish hay'. 96 The 'Irish Hey' is named as a bagpipe-tune in the previously mentioned 'West Country Jigg: or, A Trenchmore Galliard' (1672) 97 and, coincidentally, appears to have been as boisterous as 'Trenchmore'. 98 The bagpipe also appeared, or was to be heard, when Irish soldiers and military customs were portrayed on stage during the sixteenth and seventeenth centuries. 99

A hint that the dance 'Trenchmore' could have been performed to the bagpipe in 1551-2 lies in the tune itself. As Playford published it in 1652, 'Trenchmore' is a single eight-bar strain in circular form, intended, no doubt, to be repeated as often as dancers required. With its 6/4 time-signature, a range of an octave and a strong, simple structure, it could easily have been a bagpipe-tune. The notes fall comfortably on a piper's fingers, and the tune sounds well on the pipe-chanter. Other tunes that possibly originated on a bagpipe have been detected in English collections of the sixteenth and seventeenth centuries, and 'Trenchmore' would fulfil the criteria by which these tunes were identified. 100 And while it may be no more than coincidence, 'Trenchmore' has left a family of descendants, hitherto unrecognised, in the surviving piping traditions of Great Britain and Ireland. 101

## V

In the absence of another, more convincing explanation of the title 'Trenchmore', the circumstantial evidence that the dance originated in co. Kilkenny, and that members of the earl of Ormond's household introduced it as a novelty to the court of Edward VI, appears fairly convincing. Why the dance and tune should have become so popular is now impossible to say; perhaps the courtiers found its wildness a refreshing change from the graceful formality of the court dances, mostly of French and Italian origin. Though descendants of 'Trenchmore' survived as jigs, the original form of the tune could enable a dance-scholar to determine what type of dance it was in the first place. Almost certainly there would have been nothing particularly Irish, in the sense of Gaelic, about the dance; along with the dances found in the Pale, it is more likely to have been one of the old types that survived in Ireland after dying out elsewhere. It has been argued that that the dancing customs in areas like the Pale and co. Kilkenny could not reflect those of the more Gaelic areas of Ireland. But the fact that both the terms for dancing in Irish - damhsa and rince - are both loanwords implies that the type of dancing to which they applied was also borrowed into Gaelic society.

Further 'Irish' dances were to be found in England during the sixteenth and seventeenth centuries - none of them attested as well as 'Trenchmore' - and a recognisably Irish style of dancing was to be seen on the London stage by the 1590s.

Obviously stage-dances would more than likely have been character performances, probably intended to reinforce the 'wild Irish' stereotype. From the 1650s on the occasional country dance with 'Irish' in the title appeared in print. Several of these were fairly common dance-types, and it is impossible to say if the adjective meant that the dance itself originated in Ireland, or if it was merely danced to an Irish tune. As we have seen above, things Irish seem not to have been as popular with Queen Elizabeth I and her court as they were her brother Edward VI and his. But as the queen declined in the last years of her life - she died in March 1603 - one of her pleasures was to watch her courtiers dance country dances. In 1600 it was reported that

'Her Majestie is in very good health, and comes much abroad these holidays; for almost every night she is in the presence, to see the ladies daunce the old and new Countrey dances, with the taber and pipe.' 102

 The 'old' country dances were probably the traditional ones originating in rural areas, while the 'new' dances are likely to have been sophisticated figured dances that developed under the influence of the court-dances. 103 More intriguing, from an Irish point-of-view, is a letter the earl of Worcester wrote to the earl of Shewsbury in December 1602:

'We are frolyke heare in Cowrt; mutche dauncing in the privi chamber of contrey dawnces before the Q.M., whoe is exceedingky pleased therewith: Irish tunes are at this tyme most pleasing; but in wynter, Lullaby, an owld song of Mr. Bird's wyl be more in request, as I think'. 104

Elizabeth's 'black husband', Thomas, earl of Ormond, survived his royal mistress by eleven years, blind and increasingly suspicious and querulous as the succession to his lands and title hung in the balance in the absence of a legitimate son. But the popularity of 'Trenchmore' and 'Callino', both of which he is likely to have been the means of transmitting to the Tudor court, outlasted him and many of his successors. For 'Callino' was, like 'Trenchmore', to leave descendants in traditional music, and songs are still sung to variants of the air. 105


## NOTES
1. The tune is discussed in William Chappell, The ballad literature and popular music of the olden time (2 vols., London 1858-9), I, pp 82-4; Claude M. Simpson, The British broadside ballad and its music (New Brunswick, 1963), pp 716-17; John M. Ward, 'Apropos The British broadside ballad and its music', Journal of the American Musicological Society xx, 1 (Spring 1967), 77-8.

2. E.K. Chambers, The medieval stage (2 vols., Oxford, 1903), I, pp 403-4.

3. Albert Feuillerat (ed.), Documents relating to the revels at the court at the time of King Edward VI and Queen Mary (London, 1914), p. 79.

4. Stephen Gosson, The school of abuse, containing a pleasant invective against poets, pipers, players, jesters, &c (London, 1598), ed. by Edward Arber (London, 1841), p. 23.

5. Merritt E. Lawless (ed.), The novels of Thomas Deloney (Indiana, 1961), p. 192.

6. William G. Baskervill, The Elizabethan jig and related song drama (New Haven [Conn.], 1963), pp 342-3, 362.

7. Nicholas Breton, Wits Trenchmour, in a conference had betwixt a scollar and an angler (London 1597), in A.B. Grosart (ed.), The works of Nicholas Breton (2 vols., London, 1879), II, p. 20.

8. Christopher Devlin, The life of Robert Southwell, poet and martyr (London, 1956), p. 283. Hanging in fetters was considered to be more effective than the rack and, technically, the victim had not been tortured (ibid., pp 284-6).

9. John Marston, The metamorphisis of Pigmalions image & certain satires (London, 1598), ii, 145 (quoted in the Oxford English Dictionary under 'Trenchmore').

10. Ralph Holinshed (ed.), The chronicles of Englande, Irelande and Scotlande (2 vols., London, 1577), II, p. 16. For 'Quipassa', or 'Chi Passa', see Simpson, Broadside ballad, pp 101-3.

11. Hans Wallrath (ed.), William Sampson's Vow Breaker (Louvain, 1914) p. 28.

12. William Chappell and Joseph Woodfall Ebsworth (eds.), The Roxburghe ballads (9 vols., Hertford, 1875-99), VII, pp 343-4. Simpson, Broadside ballad, p. 718, suggests that 'Trenchmore' in the title simply meant a fast, tripping rhythm.

13. The London Prodigal 'by William Shakespeare' (London, 1605; rep. 1910), Act I, Scene 2.

14. Simpson, Broadside ballad, p. 717.

15. Margaret Dean Smith and E.J. Nichol, 'THE DANCING MASTER: Part III, our country dances', Journal of the English Folk Dance Society iv, 6 (Dec. 1945), 226-7.

16. Kemps nine daies wonder: performed in a Morris daunce from London to Norwich (London, c. 1600), ed. by Alexander Dyce (London, 1839), pp 1, 6.

17. Quoted in Simpson, Broadside ballad, p. 717.

18. Fredson Bowers (ed.), The dramatic works in the Beaumont and Fletcher canon (8 vols., Cambridge, 1966-92), V, p. 635; VI, pp 113, 182.

19. Frederick Pollock (ed.), The table talk of John Selden (London, 1927), p. 65. The 1890 edition of this work, edited by Arthur Warwick, differs in minor details, though the dance-title is spelt in the normal way (p. 77); see also Note 43 below.

20. Robert Burton, The anatomy of melancholy, ed. by Thomas C. Faulkner, Nicholas K. Keissling and Rhonda H. Blair (3 vols., Oxford, 1990-4 [in progress]), III, p. 190.

21. Chappell, Popular music, p. 82.

22. William Bullein, A dialogue against the feuer pestilence (London, 1564), ed. by Mark W. Bullen and A.H. Bullen; part I (London, 1888), p. 94. For 'Hey de gie', a country-dance in vogue from 1529 to the 1630s, see J.P. Cunningham, 'The country dance: early references', Journal of the English Folk Dance Society ix, 3 (Dec. 1962), 148-54.

23. Chappell, Popular music, p. 82.

24. The dramatick works of his grace George Villiers, late Duke of Buckingham (2 vols., London, 1715), II, pp 93-4. Sir John Hawkins, A general history of the science and practice of music (5 vols., London, 1776), IV, p. 392, erred slightly in stating that the characters actually danced the hey to 'Trenchmore'.

25. Poems on affairs of state (5 vols., London, 1697-1707), III, pp 139-41.

26. Simpson, Broadside Ballad, p. 716.

27. William Chappell (ed.), Old English popular music (2 vols., London, 1838-9); new rev. ed. in one vol. by H. Ellis Wooldridge, with additions by Frank Kidson (New York, 1961) I, pp 226-7.

28. Jeremy Barlow (ed.), The complete country dance tunes from Playford's DANCING MASTER (London, 1985), p. 38.

29. Vol., IV, pp 469, 481.

30. Dean Smith and Nichol, 'THE DANCING MASTER: Part III,' 226.

31. Ibid.

32. Notes and queries 1, iii (Jan.-June 1851), 89, 437; 4, iii (Jan.-June 1869), 18-19; 5, xi (Jan.-June 1879), 488, xii (July-Dec. 1879), 74.

33. Breandán Breathnach, Dancing in Ireland (Miltown Malbay, 1983), p. 14; George S. Emmerson, A social history of Scottish dance (Montreal, 1972), pp 203, 266.

34. J.F. and T.M. Flett, 'The history of the Scottish reel as a dance form I', Scottish Studies xiv, 2 (1972), 110-111.

35. For anglicised forms of rince in minor placenames, see P.W. Joyce, The origin and history of Irish names of places (3 vols., Dublin, 1869; 4th ed. Dublin, 1879) I, pp 211 -12.

36. K.M. Elisabeth Murray, Caught in the web of words: James Murray and the Oxford English Dictionary (New Haven [Conn.], 1977; pbk. ed., Oxford, 1979), pp 310-11.

37. Murray's suggestion still stands in the 2nd edition of the OED (1989).

38. Mark Anthony Lower (ed.), A dictionary of surnames (Lewes, 1860; rep. Ware, 1988); C.W. Bardsley (ed.), A dictionary of English and Welsh surnames (New York, 1901); C.M. Matthews, English surnames (London, 1966); George F. Black (ed.), The surnames of Scotland (New York, 1946).

39. Charles Nicholls, The creature in the map: Sir Walter Ralegh's quest for El Dorado (London, 1995), p. 359.

40. Ward, 'Apropos The British Broadside Ballad ...', refers to an old tune called 'Browning', or 'The Leaves be Green', and the first title could have been a surname (65, 69).

41. A.H. Smith (ed.), English place-name elements (2 vols., Cambridge, 1956), II, p. 185.

42. I wish to thank Ms Rachel Harris, Ordnance Survey, Romsey Road, Southampton, for this information.

43. Edward Arber (ed.), Table-talk of John Selden (London, 1868), p. 62; p. 62; Eilert Ekwall (ed.), The concise Oxford dictionary of English placenames (Oxford, 1936; 4th ed., 1960), p. 187. Later editions of the Table-talk, however, spell the dance-title in the usual way (Note 19 above).

44. Liosta Logainmneacha: Chill Chainnigh (Baile Átha Cliath, 1993), p. 45.

45. Ibid.

46. Owen O'Kelly, The placenames of co. Kilkenny (Kilkenny, 1985), p. 145.

47. Letter of 17 August 1995 from Art Ó Malfabhail, Priomhoifigeach Logainmneacha, Ordnance Survey, Phoenix Park, Dublin 7.

48. Royal Irish Academy MS g. v. 2.

49. National Library of Ireland MS 720, passim; see also, Julian C. Walton (ed.), 'The Hearth Money Roll of co. Kilkenny - extracted from the Carrigan MSS', The Irish genealogist v, 1 (Nov. 1974), 44, 4 7.

50. For examples, see tr(e)inse in (Contributions to) a dictionary of the Irish language (Dublin 1912-72).

51. G. Mac Niochaill (ed.), Crown surveys of Irish lands 1540-41, ... (Dublin, 1992), p. 162.

52. F.E. Ball, A history of county Dublin (6 vols., Dublin, 1902-20) IV, p. 102.

53. Michael O'Flanagan (ed.), Letters containing information relative to the antiquities of the county of Kilkenny collected during the progress of the Ordnance Survey in 1839 (2 vols., Bray, 1927), I, pp 94-5.

54. R.C. Simington (ed.), The Civil Survey A.D. 1654-1656: Vol I, County Tipperary, eastern and southern baronies (Dublin, 1931), p. 100.

55. Michael McGrath (ed.), Cinnlae Amhlaoibh Uí Shúilleabheáin, ... (4 vols., London, 1936-7), I, pp 26-7.

56. For English examples, see Nicholas Temperley, 'John Playford and the metrical psalms', Journal of the American Musicological Society xxv, 3 (Fall 1972), 336; and for the Scottish psalms, see The new Grove dictionary of music and musicians (London, 1980), VX, pp. 373-4.

57. Gale Huntingdon, Lani Hermann and John Moulden (eds.), Sam Henry's SONGS OF THE PEOPLE (Athens [GA.] and London, 1990), pp 2-3.

58. Cláirseach [Ann and Charlie Heyman], Héman Dubh, CM 0897 (1997), track 11.

59. David Fallows, 'The Gresely Dance Collection, c. 1500', Royal Musical Association Research Chronicle xxix (1996), 5-6.

60. Cunningham, 'The country dance', passim.

61. Barlow, Complete country dance tunes, passim.

62. Joan Rimmer, 'Carole, rondeau & branle in Ireland: Part II, social and theatrical residues 1550-I800', Dance Research viii, no. 2 (Autumn 1996), 28.

63. Graham Kew (ed.), The Irish sections of Fynes Moryson's unpublished ITINERARY (Dublin, 1998), p. 112.

64. John Walsh, Caledonian country dances (London 1733; 2nd ed., London 1736), p. 45

65. William Van Lemep et al. (eds), The London Stage 1660-1800 (5 vols, Carbondale [Ill.] 1965-8) III, pp800-2, 804, 810, 811, 817, 834, 841, 872, 896.

66. Trinity College, Dublin MS 3194, p. 114, and MS 3196, p. 74; Arthur Darley and P.J. McCall (eds.), The Feis Ceoil Collection of Irish airs I (Dublin, 1914), p. 31; Seán Ó Súilleabháin, Caitheamh aimsire ar thórraimh (Baile Átha Cliath, 1961), p. 18.

67. The only Irish writer to mention 'Trenchmore' was the Oxford-educated Richard Stanihurst (Note 10 above).

68. Cunningham, 'The country dance', 149-53.

69. Simpson, Broadside ballad, pp 79-80; Gerard Murphy, 'Calen o custure me, Éigse i (1939), 128-9; Colm [Ó Lochlainn], 'Cailín ó chois tSiúire mé', Éigse ii (1940), 198 -9.

70. Some account of the family of the Butlers, ... (London, 1716), p. 24; Thomas Carte, A history of the life of James, Duke of Ormonde (2 vols., London, 1736), I, p. lxiv.

71. John Kirwan, 'Thomas Butler, 10th earl of Ormond, his early career and rise to prominence: part I', The journal of the Butler Society iii, 4 (1994), 514-18, 520, 522, 525; Feuillerat, Revels ... of Edward VI, p. 60.

72. Kirwan, 'Thomas Butler', 525.

73. Ciaran Brady, 'Thomas Butler, earl of Ormond (1531-1614) and reform in Tudor Ireland', in Ciaran Brady (ed.), Worsted in the game: losers in Irish history (Dublin, 1989), p. 49.

74. James Carney (ed.), Poems on the Butlers of Ormond, Cahir and Dunboyne (1400-1650) (Dublin, 1945), p. 74.

75. John Bradley, 'Kilkenny - The Faire Citie', in Howard B. Clarke (ed.), Irish cities (Cork and Dublin, 1995), p. 153.

76. Carney, Poems on the Butlers, p. 75.

77. For an account of Cruise, see Nicholas Carolan, 'Philip O'Sullivan Beare and Irish music', Éigse Cheol Tíre v (forthcoming).

78. Anthony Munday, A banquet of daintie conceits (London, 1588), in T. Park (ed.), The Harleian Miscellany IX (London, 1812), pp 249-51.

79. Ifor Williams, 'Cerrdorion a cherddau yn Lleweni, Nadolig 1595', Bulletin of the Board of Celtic Studies viii (1937), 9.

80. Victor Treadwell, Buckingham and Ireland, 1616-28: a study in Anglo-Irish politics (Dublin, 1998), pp 122, 124.

81. Carney, Poems on the Butlers, p. 74.

82. Ciaran Brady, Shane O'Neill (Dublin, 1996), p. 20.

83. James Hogan, 'Shane O'Neill come to the court of Elizabeth', in Séamas Pender (ed.). Féilscríbhinn Tórna (Cork, 1947), pp 154-70.

84. Dean Gunter White, 'Henry VIII's Irish kerne in France and Scotland, 1544-1545', The Irish Sword iii (1957-8), 217-18.

85. Feuillerat, Revels ... of Edward VI, p. 183.

86. I am grateful to Alan Fletcher of University College, Dublin, for this information.

87. W.J. Lawrence, 'Dramatic mirroring of Irish life and character', The Dublin Magazine xii, 2 (April-June 1937), 39-40.

88. Feuillerat, Revels ... of King Edward VI, pp 54-5.

89. Lawrence, 'Dramatic mirroring', 40; Stephen Orgel (ed.), Ben Jonson: the complete masques (New Haven [Conn.], 1969), pp 208-10.

90. D.B. Quinn, The Elizabethans and the Irish (Ithaca [NY], 1968), p. 151; Edward H. Sugden (ed.), A topographical dictionary to the works of Shakespeare and his fellow dramatists (Manchester, 1925), p. 273.

91. Feuillerat, Revels ... of King Edward VI, pp 48, 55; idem. (ed.), Documents relating to the Office of the Revels in the time of Queen Elizabeth (London, 1908), p. 23.

92. Idem., Revels ... of King Edward VI, pp 90-1, 123.

93. J.G. Nichols (ed.), The diary of Henry Machyn, citizen and merchant tailor of London, from AD 1550 - AD 1567 (London, 1848), pp 13, 28.

94. Feuillerat, Revels ... of King Edward VI, p. 79.

95. Orgel, Ben Jonson, p. 209.

96. Fredson Bowers (ed.), The dramatic works of Thomas Dekker (4 vols., Cambridge, 1953-61) IV, p. 361.

97. Note 12 above.

98. Thomas Dekker, A Strange Horse Race (London, 1613), quoted in Cyrus Hoy, Introduction, notes and comments to THE DRAMATIC WORKS OF THOMAS DEKKER, ed. by Fredson Bowers (4 vols., Cambridge, 1980) IV, p. 155; Sir William Davenant, The Siege (London, c. 1630), in The works of Sir William Davenant (London, 1673), p. 80.

99. J.O. Bartley, Teague, Shenkin and Sawney (Cork, 1951), pp 15, 106.

100. Roderick D. Cannon, 'English bagpipe music', Folkmusic journal iii, 2 (1972), 176-219.

101. I hope to deal elsewhere with these tunes.

102. Cunningham, 'The country dance', 151.

103. Ibid., 148-9; Margaret Dean-Smith, 'English tunes common to "Playford's Dancing Master," the keyboard books and traditional songs and dances', Proceedings of the Royal Musical Association LXXIX (1953), 12-13.

104. Cunningham, 'The country dance', 151; HMC Shrewsbury-Talbot Papers II (London, 1971), p. 256.

105. Breandán Breathnach, Folk music and dances of Ireland (Dublin, 1972), pp 19-20; Donal O'Sullivan, with Micheál Ó Súilleabháin (eds.), Bunting's ancient music of Ireland (Cork, 1983), pp 100-1.
`;

const Trenchmore = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default Trenchmore;
